import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation
} from "react-router-dom";
import { Loading } from "../Loading/Loading";
import { Login as LoginLayout} from '../Layouts/Login';

export const PrivateRoute= ({ children }) => {
    const [loading, setLoading] = useState(true);
    const auth = getAuth();
    var location = useLocation();

    useEffect(() => {
        onAuthStateChanged(auth, () => {
            setLoading(false)
        })
    })

    if (loading) {
        return <LoginLayout><Loading /></LoginLayout>
    }

    if (!auth.currentUser) {
        return <Navigate to={{pathname: "/login", state: {from: location}}} />
    }
    return children
}