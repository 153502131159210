import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

import Demo from '../../Pages/Demo/Demo';
import { Login } from "../../Pages/Auth/login";
import { Dashboard } from "../../Pages/Dashboard/Dashboard";
import { Default } from "../Layouts/Default";
import { Login as LoginLayout} from '../Layouts/Login';
import { ThemeContextProvider } from "../Contexts/Theme";
import { LanguageContextProvider } from "../Contexts/Language";

var Router = () => {
	return (
		<ThemeContextProvider>
			<LanguageContextProvider>
				<BrowserRouter>
						<Routes>
							<Route path="/login" element={<LoginLayout><Login /></LoginLayout>} />
							<Route path="/" element={<Demo />} />
							<Route path="/dashboard" element={<PrivateRoute><Default><Dashboard /></Default></PrivateRoute>} />
						</Routes>
				</BrowserRouter>
			</LanguageContextProvider>
		</ThemeContextProvider>
	);
}

export default Router;