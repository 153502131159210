import { useContext, useEffect, useState } from "react";
import { getAuth, signInWithRedirect, GoogleAuthProvider, setPersistence, browserSessionPersistence, onAuthStateChanged } from "firebase/auth";
import { SAMLAuthProvider } from "firebase/auth";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Loading } from "../../Components/Loading/Loading";
import { Form, Panel, ButtonToolbar, Button, Input, IconButton } from "rsuite";
import { Icon } from '@rsuite/icons';
import * as faGoogle from '@fortawesome/free-brands-svg-icons/faGoogle';
import SigninWithgoogle from '../../Assets/signin_with_google.png';
import { themeContext } from "../../Components/Contexts/Theme";
import { useMode } from "../../Components/Hooks/useMode";
import { ThemeSwitch } from "../../Components/ThemeSwitch/ThemeSwitch";

const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
      <svg {...rest} viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor">
        <path d={svgPathData}></path>
      </svg>
    );
  };

export var Login = () => {
    const [loading, setLoading] = useState(true);
    const [setDark, setLight] = useMode();
    const auth = getAuth();
    var location = useLocation();

    var signInWithSAML = () => {
        setPersistence(auth, browserSessionPersistence)
            .then(() => {
                const provider = new SAMLAuthProvider('saml.google-auth');
                signInWithRedirect(getAuth(), provider);
            })
    }

    var signinWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider);
    }

    useEffect(() => {
        onAuthStateChanged(auth, () => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <Loading />
    }

    if (auth.currentUser) {
        return <Navigate to={{ pathname: "/dashboard", state: { from: location } }} />
    }
    return (
        <>
            <Panel header="Login" shaded>
                <Form>
                    <Form.Group controlId="email">
                        <Form.ControlLabel>Email</Form.ControlLabel>
                        <Form.Control name="email" type="email" />
                        <Form.HelpText tooltip>Email is required</Form.HelpText>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <Form.Control name="password" type="password" />
                        <Form.HelpText tooltip>Password is required</Form.HelpText>
                    </Form.Group>
                    <Form.Group>
                        <ButtonToolbar>
                            <Button appearance="primary">Submit</Button>
                            <Button appearance="default">Cancel</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </Form>
            </Panel>
            <Panel header="Other Login methods" shaded>
                <Form>
                    <Form.Group>
                        <IconButton onClick={signinWithGoogle}  appearance="primary" icon={<Icon as={FaSvgIcon} faIcon={faGoogle} />}>
                            Sign in with Google
                        </IconButton>
                    </Form.Group>
                    <Form.Group>
                        <Button onClick={signInWithSAML}>Sign in with Federated login (SAML SSO)</Button>
                    </Form.Group>
                </Form>
            </Panel>
            <Panel>
                <ThemeSwitch />
            </Panel>
        </>
    )
}