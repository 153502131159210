import React, { useContext } from 'react';
import { CustomProvider, Container, Header, Content } from 'rsuite';
import { themeContext } from '../Contexts/Theme';
import { SideNavigation } from '../Navigation/SideNavigation';
import { TopNavigation } from '../Navigation/TopNavigation';

export const Default = ({ children }) => {
    const theme = useContext(themeContext);

    return (
        <CustomProvider theme={theme.theme}>
            <div className="show-fake-browser sidebar-page">
                <Container>
                        <Header>
                            <TopNavigation />
                        </Header>
                    <Container>
                        <SideNavigation />
                        <Content>
                            {children}
                        </Content>
                    </Container>
                </Container>
            </div>
        </CustomProvider>
    );
}