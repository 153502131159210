import { useContext } from "react"
import { Col, Header, Container, Content, CustomProvider, FlexboxGrid } from "rsuite"
import { themeContext } from "../Contexts/Theme"

export const Login = ({children}) => {
    const theme = useContext(themeContext);

    return  <CustomProvider theme={theme.theme}>
        <Container>
            <Header></Header>
            <Content>
                <FlexboxGrid justify="center" align="middle">
                    <FlexboxGrid.Item as={Col}>
                        {children}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        </Container>
    </CustomProvider>
}