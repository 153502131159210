import { useState } from 'react';
import { Sidebar, Sidenav, Navbar, Nav } from 'rsuite';
import CogIcon from '@rsuite/icons/legacy/Cog';
import AngleLeftIcon from '@rsuite/icons/legacy/AngleLeft';
import AngleRightIcon from '@rsuite/icons/legacy/AngleRight';
import DashboardIcon from '@rsuite/icons/Dashboard';
import GroupIcon from '@rsuite/icons/legacy/Group';
import { getAuth, signOut } from "firebase/auth";
import {
    Link,
    Navigate,
    useLocation
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const NavToggle = ({ expand, onChange }) => {
    const auth = getAuth();
    var location = useLocation();

    var logOut = () => {
        signOut(auth).then(() => {
            return <Navigate to={{ pathname: "/", state: { from: location } }} />
        }).catch(console.error)
    }

    return (
        <Navbar appearance="subtle" className="nav-toggle">
            <Nav>
                <Nav.Menu
                    noCaret
                    placement="topStart"
                    trigger="click"
                    title={<CogIcon style={{ width: 20, height: 20 }} size="sm" />}
                >
                    <Nav.Item>Help</Nav.Item>
                    <Nav.Item>Settings</Nav.Item>
                    <Nav.Item onClick={logOut}>Sign out</Nav.Item>
                </Nav.Menu>
            </Nav>

            <Nav pullRight>
                <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
                    {expand ? <AngleLeftIcon /> : <AngleRightIcon />}
                </Nav.Item>
            </Nav>
        </Navbar>
    );
};
export const SideNavigation = () => {
    const [t] = useTranslation();
    const [expand, setExpand] = useState(false);
    return <Sidebar
        style={{ display: 'flex', flexDirection: 'column' }}
        width={expand ? 260 : 56}
        collapsible
    >
        <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
            <Sidenav.Body>
                <Nav>
                    <Nav.Item eventKey="1" active icon={<DashboardIcon />} as={Link} to="/dashboard">{t("dashboard.title")}</Nav.Item>
                    <Nav.Item eventKey="2" icon={<GroupIcon />} as={Link} to="/accounts">{t("global.account", {count: 0})}</Nav.Item>
                    <Nav.Item eventKey="3" icon={<GroupIcon />} as={Link} to="/customer">{t("global.customer", {count: 0})}</Nav.Item>
                </Nav>
            </Sidenav.Body>
        </Sidenav>
        <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
    </Sidebar >
}