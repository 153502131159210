import { createContext, useEffect, useState } from "react";


export const themeContext = createContext({});


export const ThemeContextProvider = ({children}) => {
    const [theme, setThemeState] = useState("");
    useEffect(()=>{
        if (theme === "") {
            setThemeState(localStorage.getItem("theme"));
        } else {
            localStorage.setItem("theme", theme);
        }
    }, [theme])

    const setTheme = (value) => {
        setThemeState(value)
    }

    return <themeContext.Provider value={{theme, setTheme}}>
        {children}
    </themeContext.Provider>
}