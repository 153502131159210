import { useContext } from "react";
import { themeContext } from "../Contexts/Theme";

export const useMode = () => {
    const theme = useContext(themeContext);

    var setToDark = () => {
        theme.setTheme("dark")
    }

    var setToLight = () => {
        theme.setTheme("light")
    }

    var setToHighContrast = () => {
        theme.setTheme("high-contrast")
    }

    return [setToLight, setToDark, setToHighContrast]
}