import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";


export const languageContext = createContext();

export const LanguageContextProvider = ({children}) => {
    const [lang, setLangState] = useState();
    const {i18n} = useTranslation();

    const setLang = (value) => {
        i18n.changeLanguage(value);
        setLangState(value)
    }

    return <languageContext.Provider value={{lang, setLang}}>
        {children}
    </languageContext.Provider>
}