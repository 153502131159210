import { Navbar, Nav } from 'rsuite';
import DashboardIcon from '@rsuite/icons/Dashboard';
import CogIcon from '@rsuite/icons/legacy/Cog';
import { getAuth, signOut } from "firebase/auth";
import {
    Link,
} from "react-router-dom";
import { ThemeSwitch } from '../ThemeSwitch/ThemeSwitch';
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch';
import { useTranslation } from 'react-i18next';

export const TopNavigation = () => {
    const auth = getAuth();
    const [t] = useTranslation();

    var logOut = () => {
        signOut(auth).then(() => {
            window.location.reload(true);
        }).catch(console.error)
    }

    return <Navbar>
        <Navbar.Brand href="#">{t("global.productName")}</Navbar.Brand>
        <Nav pullRight>
            <Nav.Item icon={<DashboardIcon />} as={Link} to="/dashboard">Home</Nav.Item>
            <Nav.Item><LanguageSwitch /></Nav.Item>
            <Nav.Item><ThemeSwitch /></Nav.Item>
            <Nav.Item icon={<CogIcon />} onClick={logOut} >Logout</Nav.Item>
        </Nav>
    </Navbar>
}