import { initializeApp } from "firebase/app";


import Router from './Components/Router/Router';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyD2TzeVrBLAwZJfv-uTv-SlqstWJwG4klc",
    authDomain: "levypay.firebaseapp.com",
    projectId: "levypay",
    storageBucket: "levypay.appspot.com",
    messagingSenderId: "596744133941",
    appId: "1:596744133941:web:5cf7eaf0d6587f71fc407c",
    measurementId: "G-967096H5RP"
  };
  
  initializeApp(firebaseConfig);
    
  return <Router />
}

export default App;