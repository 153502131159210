import { useContext } from "react"
import { languageContext } from "../Contexts/Language"
import { SelectPicker } from 'rsuite';


const langOptions = [
    {lang: "levypay", name: "Levypay"},
    {lang: "tenanc", name: "Tenanc"},
    {lang: "membership", name: "Membership"}
]

export const LanguageSwitch = ({appearance}) => {
    const lang = useContext(languageContext)
    const data = langOptions.map(item => ({label: item.name, value: item.lang}))

    const selectedOption = (selection) => {
        lang.setLang(selection)
    }

    return <SelectPicker label="Platform" data={data} style={{ width: 224 }} onSelect={selectedOption}/>
}