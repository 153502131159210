import { useContext } from 'react';
import { CustomProvider, Loader } from 'rsuite';
import { themeContext } from '../Contexts/Theme';

export const Loading = () => {
    const theme = useContext(themeContext);

    return <CustomProvider theme={theme.theme}>
        <Loader size="lg" content="Loading..." vertical />
    </CustomProvider>
}