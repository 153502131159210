import { Button, ButtonToolbar, ButtonGroup } from 'rsuite';
import { useMode } from '../Hooks/useMode';

export const ThemeSwitch = ({appearance}) => {
    const [setLight, setDark, setHigh] = useMode();

    return <ButtonToolbar>
        <ButtonGroup>
            <Button appearance={appearance} onClick={setLight}>Light</Button>
            <Button appearance={appearance} onClick={setDark} >Dark</Button>
            <Button appearance={appearance} onClick={setHigh}>High Contrast</Button>
        </ButtonGroup>
    </ButtonToolbar>
}